'use client'

import { Breakpoints } from 'fukku/styles/breakpoints'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import Image from 'next/image'
import { useRef } from 'react'
import { pointOfInterestImageLoaderFactory } from 'utils/image/loaders/pointOfInterestImageLoaderFactory'
import { isBot } from 'utils/isBot/IsBot'
import styles from './FamilyBanner.module.scss'

interface FamilyBannerImageProps {
	readonly image: string
	readonly imageHorizontalPosition: number
	readonly imageVerticalPosition: number
	readonly priority: boolean
	readonly imageSizes?: string
	readonly relativeWidth?: {
		small: number
		medium: number
		large: number
	}
}

const ASPECT_RATIO_WIDTH = 5
const ASPECT_RATIO_HEIGHT = 7
const ASPECT_RATIO = ASPECT_RATIO_WIDTH / ASPECT_RATIO_HEIGHT

const DEFAULT_POI_X = 0
const DEFAULT_POI_Y = 0
const DEFAULT_POI = {
	x: DEFAULT_POI_X,
	y: DEFAULT_POI_Y,
}

const DEFAULT_RELATIVE_WIDTH = {
	small: 1,
	medium: 1,
	large: 1,
}

export const FamilyBannerImage = ({
	image,
	imageHorizontalPosition,
	imageVerticalPosition,
	imageSizes,
	relativeWidth,
	priority,
}: FamilyBannerImageProps) => {
	const imageWrapperRef = useRef(null)
	const entry = useIntersectionObserver(imageWrapperRef, {
		freezeOnceVisible: true,
	})

	const imageRelativeWidth = relativeWidth ?? DEFAULT_RELATIVE_WIDTH

	const imageLoader = pointOfInterestImageLoaderFactory(
		DEFAULT_POI,
		(width) => width / ASPECT_RATIO,
		(width) => {
			if (width <= Breakpoints.medium) {
				return width * imageRelativeWidth.small
			}
			if (width <= Breakpoints.large) {
				return width * imageRelativeWidth.medium
			}
			return width * imageRelativeWidth.large
		}
	)

	return (
		<div ref={imageWrapperRef} className={styles.imageContainer}>
			{entry?.isIntersecting || isBot() ? (
				<Image
					style={{
						objectPosition: `${imageHorizontalPosition}% ${imageVerticalPosition}%`,
					}}
					sizes={imageSizes ?? '100vw'}
					alt=''
					src={image}
					priority={priority}
					draggable={false}
					loader={imageLoader}
					fill
				/>
			) : null}
		</div>
	)
}

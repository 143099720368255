'use client'

import { SelectPromotion } from 'analytics/components/SelectPromotion/SelectPromotion'
import dynamic from 'next/dynamic'
import { applyStylesIf, cx } from 'utils/cx'

import styles from './PromotionalBannerCtas.module.scss'
import type { PromotionalBannerCtasProps } from './PromotionalBannerCtasTypes'

const ClientLinkPrimary = dynamic(() =>
	import('@fukku/button/ClientLink/Primary').then(
		(mod) => mod.ClientLinkPrimary
	)
)

export const DynamicPromotionalBannerCtas = ({
	ctas,
	isLow,
	isSingleCta,
}: PromotionalBannerCtasProps) => (
	<div
		className={cx(
			styles.ctasWrapper,
			applyStylesIf(isLow, styles.low),
			applyStylesIf(isSingleCta, styles.singleCta)
		)}
	>
		{ctas.map((cta) => (
			<SelectPromotion promotion={cta.promotion} key={cta.ctaId}>
				<ClientLinkPrimary
					href={cta.href}
					className={cx(styles.ctaButton, applyStylesIf(isLow, styles.low))}
					linkProps={cta.linkProps}
					isLocalizedPath={cta.isLocalizedPath}
				>
					{cta.text}
				</ClientLinkPrimary>
			</SelectPromotion>
		))}
	</div>
)

'use client'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import Image from 'next/image'
import { type CSSProperties, useRef } from 'react'
import { useResponsive } from 'responsive/hooks/useResponsive/useResponsive'
import { isBot } from 'utils/isBot/IsBot'

import styles from './FamilyDirectAccessImage.module.scss'

const IMAGE_SMALL_WIDTH = 144
const IMAGE_LARGE_WIDTH = 192
const IMAGE_ASPECT_RATIO = 1

interface FamilyDirectAccessImageProps {
	readonly src: string
	readonly pointOfInterest: {
		x: number
		y: number
	}
}

export const FamilyDirectAccessImage = ({
	src,
	pointOfInterest,
}: FamilyDirectAccessImageProps) => {
	const divRef = useRef<HTMLDivElement>(null)
	const { isLargeOrGreater } = useResponsive()
	const imageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => width / IMAGE_ASPECT_RATIO
	)
	const entry = useIntersectionObserver(divRef, {
		threshold: 0.01,
		freezeOnceVisible: true,
	})
	const imageWidth = isLargeOrGreater ? IMAGE_LARGE_WIDTH : IMAGE_SMALL_WIDTH

	return (
		<div
			ref={divRef}
			className={styles.familyImage}
			style={
				{
					'--family-small-width': `${IMAGE_SMALL_WIDTH}px`,
					'--family-large-width': `${IMAGE_LARGE_WIDTH}px`,
				} as CSSProperties
			}
		>
			{entry?.isIntersecting || isBot() ? (
				<Image
					src={src}
					alt=''
					loader={imageLoader}
					draggable={false}
					width={imageWidth}
					height={imageWidth * IMAGE_ASPECT_RATIO}
				/>
			) : null}
		</div>
	)
}

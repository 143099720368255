export enum OrderType {
	MultiWarehouse = 'multi_warehouse',
	MultiOrder = 'multi_order',
	Unique = 'unique',
}

export enum AnalyticsActionType {
	SHOW = 'show',
	CLOSE = 'close',
	CLICK = 'click',
}

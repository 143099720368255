import { type RefObject, useEffect, useLayoutEffect, useRef } from 'react'

interface DraggableOptions {
	speedDecay?: number
	minimumSpeed?: number
	frameRate?: number
	milisecondsPerSecond?: number
	minDragDistance?: number
	onDrag?: () => void
}

const SPEED_DECAY = 0.95
const MINIMUM_SPEED = 0.05
const FRAME_RATE = 60
const MILISECONDS_PER_SECOND = 1000
const MIN_DRAG_DISTANCE = 10

export function useDraggable(
	ref: RefObject<HTMLDivElement> | null,
	{
		speedDecay = SPEED_DECAY,
		minimumSpeed = MINIMUM_SPEED,
		frameRate = FRAME_RATE,
		milisecondsPerSecond = MILISECONDS_PER_SECOND,
		minDragDistance = MIN_DRAG_DISTANCE,
		onDrag = () => {},
	}: DraggableOptions = {}
) {
	const scrollState = useRef({
		isMouseDown: false,
		isDraggingX: false,
		initialMouseX: 0,
		lastMouseX: 0,
		scrollSpeedX: 0,
		lastScrollX: 0,
		maxHorizontalScroll: 0,
		speedDecay,
	})

	// timing getting 60fps
	const timing = (1 / frameRate) * milisecondsPerSecond

	useLayoutEffect(() => {
		if (ref?.current) {
			scrollState.current.maxHorizontalScroll =
				ref.current.scrollWidth - ref.current.clientWidth
		}
	}, [ref])

	const handleHorizontalTranslation = () => {
		if (!ref || !ref.current) {
			return
		}

		const translationX = scrollState.current.scrollSpeedX * timing
		const offsetX = ref.current.scrollLeft + translationX

		ref.current.scrollLeft = offsetX
		scrollState.current.lastScrollX = offsetX
	}

	let keepMovingX: NodeJS.Timeout | undefined

	const handleDrag = () => {
		keepMovingX = setInterval(() => {
			if (!ref || !ref.current) {
				return
			}

			const lastScrollSpeedX = scrollState.current.scrollSpeedX
			const newScrollSpeedX = lastScrollSpeedX * scrollState.current.speedDecay
			scrollState.current.scrollSpeedX = newScrollSpeedX

			const isMaxLeft = ref.current.scrollLeft <= 0
			const isMaxRight =
				ref.current.scrollLeft >= scrollState.current.maxHorizontalScroll
			const hasReachedHorizontalEdges = isMaxLeft || isMaxRight

			handleHorizontalTranslation()

			if (
				Math.abs(newScrollSpeedX) < minimumSpeed ||
				scrollState.current.isMouseDown ||
				hasReachedHorizontalEdges
			) {
				scrollState.current.scrollSpeedX = 0
				clearInterval(keepMovingX)
			}
		}, timing)

		scrollState.current.isDraggingX = false
	}

	const preventClick = (event: Event) => {
		event.preventDefault()
		event.stopImmediatePropagation()
	}

	const getIsMousePressActive = (buttonsCode: number) => {
		return buttonsCode === 1 || buttonsCode === 0
	}

	const onMouseMove = (mouseEvent: MouseEvent) => {
		if (!scrollState.current.isMouseDown) {
			return
		}

		mouseEvent.preventDefault()

		const translationX = scrollState.current.lastMouseX - mouseEvent.clientX
		scrollState.current.lastMouseX = mouseEvent.clientX

		scrollState.current.scrollSpeedX = translationX / timing
		scrollState.current.isDraggingX = true

		handleHorizontalTranslation()
	}

	const onMouseDown = (mouseEvent: React.MouseEvent<HTMLElement>) => {
		window.addEventListener('mouseup', onMouseUp)
		window.addEventListener('mousemove', onMouseMove)

		const isMouseActive = getIsMousePressActive(mouseEvent.buttons)

		if (!isMouseActive) {
			return
		}

		scrollState.current.isMouseDown = true
		scrollState.current.lastMouseX = mouseEvent.clientX
		scrollState.current.initialMouseX = mouseEvent.clientX
	}

	const onMouseUp = (mouseEvent: MouseEvent) => {
		if (!ref || !ref.current) {
			return
		}

		const isDragging = scrollState.current.isDraggingX
		const translationX = scrollState.current.initialMouseX - mouseEvent.clientX
		const isMotionIntentional = Math.abs(translationX) > minDragDistance
		const isDraggingConfirmed = isDragging && isMotionIntentional

		if (isDraggingConfirmed) {
			ref.current.childNodes.forEach((child) => {
				child.addEventListener('click', preventClick)
			})
		} else {
			ref.current.childNodes.forEach((child) => {
				child.removeEventListener('click', preventClick)
			})
		}

		scrollState.current.isMouseDown = false
		scrollState.current.lastMouseX = 0

		if (isDraggingConfirmed) {
			onDrag()
			handleDrag()
		}

		window.removeEventListener('mouseup', onMouseUp)
		window.removeEventListener('mousemove', onMouseMove)
	}

	const handleResize = () => {
		if (!ref || !ref.current) {
			return
		}

		scrollState.current.maxHorizontalScroll =
			ref.current.scrollWidth - ref.current.clientWidth
	}

	const removeDraggableMotion = () => {
		scrollState.current.speedDecay = 1
	}

	useEffect(() => {
		window.addEventListener('resize', handleResize)

		const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')
		mediaQuery.addEventListener('change', removeDraggableMotion)

		return () => {
			window.removeEventListener('mouseup', onMouseUp)
			window.removeEventListener('mousemove', onMouseMove)
			window.removeEventListener('resize', handleResize)
			mediaQuery.removeEventListener('change', removeDraggableMotion)
			clearInterval(keepMovingX)
		}
	}, [])

	return {
		onMouseDown,
	}
}

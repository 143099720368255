'use client'

import type { ReactNode } from 'react'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import { BUTTON_VARIANT } from '../../Button.types'
import type { ButtonClientLinkProps } from '../ButtonClientLink.types'

import styles from './ClientLinkPrimary.module.scss'

export function ClientLinkPrimary(props: ButtonClientLinkProps): ReactNode {
	const {
		children,
		variant = BUTTON_VARIANT.DEFAULT,
		className,
		fullWidth,
		...rest
	} = props

	return (
		<ClientLink
			{...rest}
			className={cx(className, styles[variant], fullWidth && styles.fullWidth)}
		>
			<span className={styles.content}>{children}</span>
		</ClientLink>
	)
}

'use client'

import { useVisibilityChange } from 'hooks/useVisibilityChange/useVisibilityChange'
import {
	type ReactNode,
	createContext,
	useContext,
	useMemo,
	useState,
} from 'react'

import { sendViewPromotionEvent } from '../events/eec/viewPromotion/viewPromotion'
import type { Promotion } from '../types/EEC'

interface AnalyticsPromotionsProviderProps {
	readonly children: ReactNode
}

type AnalyticsPromotionsContextType = {
	promotions: Promotion[]
	addPromotions: (promotions: Promotion[]) => void
}

export const AnalyticsPromotionsContext =
	createContext<AnalyticsPromotionsContextType | null>(null)

export const AnalyticsPromotionsProvider = ({
	children,
}: AnalyticsPromotionsProviderProps) => {
	const [promotions, setPromotions] = useState<Promotion[]>([])

	const addPromotions = (promotionsView: Promotion[]): void => {
		setPromotions((prevImpressions) => [...prevImpressions, ...promotionsView])
	}

	useVisibilityChange(() => {
		if (promotions.length) {
			sendViewPromotionEvent(promotions)
			setPromotions([])
		}
	})

	const promotionsValue = useMemo(
		() => ({
			promotions,
			addPromotions,
		}),
		[promotions]
	)

	return (
		<AnalyticsPromotionsContext.Provider value={promotionsValue}>
			{children}
		</AnalyticsPromotionsContext.Provider>
	)
}
export const useAnalyticsPromotions = (): AnalyticsPromotionsContextType => {
	const promotionsContext = useContext(AnalyticsPromotionsContext)

	if (!promotionsContext) {
		throw new Error(
			'useAnalyticsPromotions must be used within a AnalyticsPromotionsProvider'
		)
	}

	return promotionsContext
}

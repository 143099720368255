'use client'

import { SelectPromotion } from 'analytics/components/SelectPromotion/SelectPromotion'
import { ViewPromotion } from 'analytics/components/ViewPromotion/ViewPromotion'
import { conversion } from 'country-configuration/currency'
import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import type { FamilyBanner } from '../../../Home.type'

import text from 'fukku/styles/texts.module.scss'
import { promotionFactory } from '../../../analytics/promotionFactory'
import styles from './FamilyBanner.module.scss'
import { FamilyBannerImage } from './FamilyBannerImage'

type FamilyBannerComponentProps = {
	readonly familyBanner: FamilyBanner
	readonly priority: boolean
	readonly slot: string
	readonly href: string
	readonly lowestPrice: number | undefined
	readonly imageSizes?: string
	readonly relativeWidth?: {
		small: number
		medium: number
		large: number
	}
}

export const FamilyBannerComponent = ({
	familyBanner,
	priority,
	slot,
	href,
	lowestPrice,
	imageSizes,
	relativeWidth,
}: FamilyBannerComponentProps) => {
	const {
		image,
		text: title,
		promotionName,
		imageHorizontalPosition,
		imageVerticalPosition,
	} = familyBanner

	const { country } = useMasterData()
	const formattedLowestPrice = lowestPrice
		? conversion(lowestPrice, country).price
		: null
	const ctaToPromotion = promotionFactory(familyBanner, slot)
	const promotion = ctaToPromotion(promotionName)

	const { t } = useLabels()

	return (
		<ViewPromotion promotions={[promotion]}>
			<SelectPromotion promotion={promotion}>
				<a href={href} className={styles.familyBanner} draggable={false}>
					<FamilyBannerImage
						image={image}
						imageHorizontalPosition={imageHorizontalPosition}
						imageVerticalPosition={imageVerticalPosition}
						imageSizes={imageSizes}
						relativeWidth={relativeWidth}
						priority={priority}
					/>
					<div className={styles.textContainer}>
						<h2 className={styles.familyBannerTitle}>{title}</h2>
						{formattedLowestPrice && (
							<div className={text.bodyM}>
								<ClientLabel placeholderValues={{ 1: formattedLowestPrice }}>
									{t('homeOutlet.cards.price.text')}
								</ClientLabel>
							</div>
						)}
					</div>
				</a>
			</SelectPromotion>
		</ViewPromotion>
	)
}

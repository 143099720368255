import type { CrossSellingServiceResponse } from 'cross-selling/types'
import type { BrandId } from 'types/brands'
import { env } from 'utils/envManager'

export interface GetCollaborativeRecommendationsParams {
	countryISO: string
	languageISO: string
	externalId?: string | null
	brandId?: BrandId
}
export async function getCollaborativeRecommendations({
	countryISO,
	languageISO,
	externalId,
	brandId,
}: GetCollaborativeRecommendationsParams): Promise<
	CrossSellingServiceResponse[]
> {
	const baseUrl = `/ws-personalization/api/collaborative/v1/recommendations/${countryISO}`
	const url = new URL(baseUrl, env.NEXT_PUBLIC_SITE_BASE_URL)

	const searchParams = new URLSearchParams({
		language: languageISO,
	})

	externalId && searchParams.append('user_id', externalId)
	brandId && searchParams.append('brand_id', brandId)
	url.search = searchParams.toString()

	const response = await fetch(url.href, {
		next: {
			revalidate: 0,
		},
	})

	if (response.ok) {
		return response.json()
	}

	console.error(
		'[CrossSelling] Failed to fetch collaborative recommendations xselling'
	)
	return []
}

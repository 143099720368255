import type { Promotion } from 'analytics/types/EEC'
import type {
	MandatoryProductImageType,
	ProductImageType,
} from 'cross-selling/types'
import type { featureFlagKeys } from 'feature-flags/constants/featureFlags'
import type {
	COMM_BANNER_BG_COLOR_TYPE,
	COMM_BANNER_TYPE,
} from 'fukku/CommsBanners/types'
import type { CarouselBannerType } from 'services/graphql/contentStack/homes/query.type'
import type { BrandId, ShopBrand } from 'types/brands'
import type { Channel } from 'types/masterData'

export type { ProductImageType } from 'cross-selling/types'

export enum SectionType {
	AbTest = 'abTest',
	HeroBannerCtas = 'heroBannerCtas',
	HeroBannerPrimaryLines = 'heroBannerPrimaryLines',
	HeroBannerSecondaryLines = 'heroBannerSecondaryLines',
	HeroBannerPromo = 'heroBannerPromo',
	FamiliesBanner = 'familiesBanner',
	FamiliesCarousel = 'familiesCarousel',
	FamilyBanner = 'familyBanner',
	FamilyBannerShop = 'familyBannerShop',
	FamiliesDirectAccess = 'familiesDirectAccess',
	FamilyDirectAccess = 'familyDirectAccess',
	CommsBanner = 'commsBanner',
	CommsCarousel = 'commsCarousel',
	LineBanner = 'lineBanner',
	LinesBanner = 'linesBanner',
	LinesDirectAccess = 'linesDirectAccess',
	CrossSellingCarousel = 'crossSellingCarousel',
	HeroBannerShop = 'heroBannerShop',
	HeroBannerShopSales = 'heroBannerShopSales',
	PromotionalBanner = 'promotionalBanner',
	CarouselBanner = 'carouselBannerShop',
	SeoBanner = 'seoBanner',
	MangoLikesYouBanner = 'mangoLikesYouBanner',
}

export type HomeChannel = Exclude<Channel, Channel.Backoffice>

export type ButtonCta = {
	href: string
	title: string
	inverse: boolean
	legacy: boolean
	noFollow: boolean
	target: string
	ctaAnalyticsId: string
}

export type SimpleCta = {
	labelKey: string
}

export type SectionSimpleCta = {
	labelKey: string
	sectionId: string
}

export type SectionCta = {
	labelKey: string
	sectionId: string | null
	brandId: string | null
	featureId?: FEATURE_ID | null
}

export type SectionCtaWithImage = {
	labelKey: string
	sectionId: string | null
	image: PointOfInterestImage
	featureId?: FEATURE_ID | null
}

export type Section =
	| AbTest
	| HeroBannerPromo
	| HeroBannerCtas
	| HeroBannerPrimaryLines
	| HeroBannerSecondaryLines
	| FamiliesBanner
	| FamiliesCarousel
	| FamiliesDirectAccess
	| CommsBanner
	| CommsCarousel
	| LinesBanner
	| LinesDirectAccess
	| CrossSellingCarousel
	| HeroBannerShop<HeroBannerShopType>
	| HeroBannerShopMultibrand
	| FamilyBannerShop
	| HeroBannerShopSales<HeroBannerShopType>
	| PromotionalBanner<PROMOTIONAL_BANNER_TYPE>
	| CarouselBanner
	| SeoBanner
	| MangoLikesYouBanner

export interface SectionComponentProps {
	section: Section
	slot: string
	isPrioritySlot?: boolean
}

export type SectionComponent = React.FC<SectionComponentProps>

export type SectionAdapter = {
	uid: string
	section: Section
	Component: SectionComponent
}

export type AbTest = {
	testKey: featureFlagKeys
	variants: SectionAdapter[]
	sectionType: SectionType.AbTest
	uid: string
}

export enum HeroBannerPromoType {
	general = 'general',
	progressive = 'progressive',
	family = 'family',
}

export enum TEXT_COLOR {
	White = 'white',
	Black = 'black',
}

export enum FEATURE_ID {
	MangoLikesYou = 'mango-likes-you',
	Discover = 'discover',
	Help = 'help',
	FashionAssistant = 'fashion-assistant',
	GiftVoucher = 'gift-voucher',
	Responsibility = 'responsibility',
}

export enum HERO_BANNER_SHOP_PLUS_TITLE_TYPE {
	INSPIRATIONAL = 'inspirational',
	MANGO_SANS = 'mangoSans',
	IMAGE = 'image',
}

export interface ImageFile {
	edges: {
		node: {
			url: string
			description: string
		}
	}[]
}

export type HeroBannerPromo = {
	promotionName: string
	image: PointOfInterestImage
	countdown: string
	promoBackgroundColor: string
	promoLegalInfo: string
	sectionId: string | null
	ctas: SectionCta[] | undefined
	promoSubtitles: {
		condition: string
		onlineExclusive: boolean
		dueDate: string
		promoCode: string
	}
	sectionType: SectionType.HeroBannerPromo
	uid: string
} & (
	| HeroBannerPromoGeneral
	| HeroBannerPromoProgressive
	| HeroBannerPromoFamily
)

export type HeroBannerPromoGeneral = {
	promoTitles: {
		title: string
		discount: string
	}
	promoType: HeroBannerPromoType.general
}

export type HeroBannerPromoProgressive = {
	progressivePromoTitles: {
		title1: string
		strategy1: string
		title2: string
		strategy2: string
	}
	promoType: HeroBannerPromoType.progressive
}

export type HeroBannerPromoFamily = {
	familyPromoTitles: {
		title: string
		subtitle: string
	}
	promoType: HeroBannerPromoType.family
}

export type HeroBannerCtas = {
	promotionName: string
	image: PointOfInterestImage
	linesCtas: boolean
	ctas: ButtonCta[]
	sectionType: SectionType.HeroBannerCtas
	uid: string
}

export type HeroBannerPrimaryLines = {
	uid: string
	promotionName: string
	catalogId: string
	ctas: SectionCta[] | undefined
	image: PointOfInterestImage
	sectionType: SectionType.HeroBannerPrimaryLines
}

export type HeroBannerSecondaryLines = {
	uid: string
	title: string
	subTitle: string
	catalogId: string
	promotionName: string
	image: PointOfInterestImage
	simpleCta: SimpleCta
	ctas: SectionCta[] | undefined
	sectionType: SectionType.HeroBannerSecondaryLines
}

export type LineBanner = {
	promotionName: string
	image: PointOfInterestImage
	text: string
	sectionId: string
	sectionType: SectionType.LineBanner
	uid: string
}

export type LinesBanner = {
	lines: LineBanner[]
	sectionType: SectionType.LinesBanner
}

export type LineBannerProps = {
	cta: Cta | null
	image: PointOfInterestImage
	text: string
	uid: string
}

export type FamilyBanner = {
	promotionName: string
	image: string
	imageHorizontalPosition: number
	imageVerticalPosition: number
	text: string
	catalogId: string
	sectionId: string
	sectionType: SectionType.FamilyBanner
	uid: string
}

export type FamiliesBanner = {
	families: FamilyBanner[]
	sectionType: SectionType.FamiliesBanner
}

export type FamiliesCarousel = {
	title: string
	showLink: boolean
	sectionId: string
	families: FamilyBanner[]
	sectionType: SectionType.FamiliesCarousel
}

export type FamilyDirectAccess = {
	promotionName: string
	image: string
	imageHorizontalPosition: number
	imageVerticalPosition: number
	textLabel: string
	sectionId: string
	sectionType: SectionType.FamilyDirectAccess
	uid: string
}

export type FamiliesDirectAccess = {
	familiesDirectAccess: FamilyDirectAccess[]
	sectionType: SectionType.FamiliesDirectAccess
}

export type CommsBanner = {
	promotionName: string
	title: string
	subtitle: string
	type: string
	linesCtas: boolean
	sectionCtas: SectionSimpleCta[]
	sectionType: SectionType.CommsBanner
	showInfo: boolean
	infoText: string
	uid: string
}

export interface PromotionContent {
	title: string
	discount: string
	subtitle: string
	subtitlesGroup: {
		titleKey: string
		subtitleKey: string
	}[]
	lineCtas: boolean
	sectionCtas: SectionCta[]
	infoText: string
	startDate: string
	endDate: string
	promotionName: string
	uid: string
}

export type CommBanner = {
	title: string
	type: COMM_BANNER_TYPE
	promotionName: string | undefined
	maxDays: number | undefined
	startDate: string | undefined
	endDate: string | undefined
	countdown: string | undefined
	subtitle: string
	subtitlesGroup:
		| {
				titleKey: string
				subtitleKey: string
		  }[]
		| undefined
	lineCtas: boolean
	sectionCtas: SectionCta[]
	discount: string | undefined
	showInfo: boolean
	infoText: string | undefined
	closable: boolean
	contentColor: TEXT_COLOR
	backgroundColorType: COMM_BANNER_BG_COLOR_TYPE
	customBackgroundColor: string
	uid: string
}

export type CommsCarousel = {
	comms: CommBanner[]
	sectionType: SectionType.CommsCarousel
	uid: string
}

export type CommCarouselItem = {
	sectionType: SectionType.CommsCarousel
	uid: string
	promotionName: string
}

export type LinesDirectAccess = {
	promotionName: string
	sectionType: SectionType.LinesDirectAccess
	uid: string
}

export enum HeroBannerShopType {
	image = 'image',
	video = 'video',
}

export enum PROMOTIONAL_BANNER_TYPE {
	High = 'high',
	Low = 'low',
}

export enum PROMOTIONAL_BANNER_CONTENT_COLOR_TYPE {
	DEFAULT = 'default',
	PROMO = 'promo',
	CUSTOM = 'custom',
}

export interface Video {
	url: string
	contentType: string
}

export interface TitleImage {
	url: string
	desktopWidth: number
	mobileWidth: number
	description: string
	aspectRatio: number | null
}

type CommonPropsHeroBannerShop = {
	uid: string
	titleType: HERO_BANNER_SHOP_PLUS_TITLE_TYPE
	titleKey: string | null
	titleImage: TitleImage | null
	mobileTitleImage: TitleImage | null
	contentColor: TEXT_COLOR
	promotionName: string
	ctas: SectionCta[]
	sectionType: SectionType.HeroBannerShop
}

export type HeroBannerShop<T extends HeroBannerShopType> =
	T extends HeroBannerShopType.image
		? {
				type: T
				video: null
				portraitImage: PointOfInterestImage | null
				landscapeImage: PointOfInterestImage | null
			} & CommonPropsHeroBannerShop
		: {
				type: T
				video: {
					videoPortrait: Video
					videoLandscape: Video
					videoPlaceholder: PointOfInterestImage | null
				}
				portraitImage: null
				landscapeImage: null
			} & CommonPropsHeroBannerShop

export type HeroBannerShopMultibrand = {
	uid: string
	titleKey: string
	portraitImage: PointOfInterestImage | null
	landscapeImage: PointOfInterestImage | null
	contentColor: TEXT_COLOR
	promotionName: string
	brandCtas: {
		brandId: string
		image: PointOfInterestImage
	}[]
	sectionCtas: SectionCtaWithImage[]
	sectionType: SectionType.HeroBannerShop
}

export type FamilySection = {
	titleKey: string
	cta: { sectionId: string; labelKey: string }
	image: PointOfInterestImage | null
}

export type FamilyBannerShop = {
	uid: string
	sectionType: SectionType.FamilyBannerShop
	promotionName: string
	contentColor: TEXT_COLOR
	familySections: FamilySection[]
}

type CommonPropsHeroBannerShopSales = {
	uid: string
	subtitleTopKey: string
	titleKey: string
	discount: string
	subtitleBottomGroup: {
		titleKey: string
		subtitleKey: string
	}[]
	subtitleBottomKey: string
	contentColor: TEXT_COLOR
	lineCtas: boolean
	sectionCtas: SectionCta[]
	showInfo: boolean
	infoText: string
	promotionName: string
	sectionType: SectionType.HeroBannerShopSales
}

export type HeroBannerShopSales<T extends HeroBannerShopType> =
	T extends HeroBannerShopType.image
		? {
				type: T
				video: null
				portraitImage: PointOfInterestImage | null
				landscapeImage: PointOfInterestImage | null
			} & CommonPropsHeroBannerShopSales
		: {
				type: T
				video: {
					videoPortrait: Video
					videoLandscape: Video
					videoPlaceholder: PointOfInterestImage | null
				}
				portraitImage: null
				landscapeImage: null
			} & CommonPropsHeroBannerShopSales

export type CommonPropsPromotionalBanner = {
	uid: string
	type: PROMOTIONAL_BANNER_TYPE
	subtitleTopKey: string
	promotionTitle: string
	isLargeDiscount: boolean
	discount: string
	subtitlesGroup: {
		titleKey: string
		subtitleKey: string
	}[]
	subtitleBottomKey: string
	lineCtas: boolean
	sectionCtas: SectionCta[]
	infoText: string
	promotionName: string
	sectionType: SectionType.PromotionalBanner
}

export type PromotionalBanner<T extends PROMOTIONAL_BANNER_TYPE> =
	T extends PROMOTIONAL_BANNER_TYPE.High
		? {
				contentColorType: null
				customContentColor: null
				image: PointOfInterestImage | null
			} & CommonPropsPromotionalBanner
		: {
				contentColorType: PROMOTIONAL_BANNER_CONTENT_COLOR_TYPE | null
				customContentColor: string | null
				image: null
			} & CommonPropsPromotionalBanner

export type HomeType = {
	sections: SectionAdapter[]
	seo: {
		description: string
		title: string
	}
}

export type HomeConfigurationLanding = {
	brandId: string
	translationKey: string
}

export type HomeConfigurationShops = {
	[key: string]: ShopBrand[]
}

export type BrandCta = {
	href: string
} & HomeConfigurationLanding

export interface Cta {
	ctaId: string
	text: string
	href: string
	linkProps: {
		title: string
		'aria-label'?: string
	}
	promotion: Promotion
	withLegacyLocale?: boolean
	isLocalizedPath?: boolean
}

export interface CtaWithImage extends Cta {
	image: PointOfInterestImage
}

export interface CommCtaShop {
	ctaId: string
	text: string
	href: string
	withLegacyLocale?: boolean
}

export type HomeConfigurationParams = {
	country: string
	channel: string
}

export type PointOfInterestImage = {
	url: string
	description?: string
	width: number
	height: number
	pointOfInterest: {
		percentageX: number
		percentageY: number
	}
}

export type CrossSellingCarousel = {
	crossSellingType: CrossSellingModelType
	promotionName: string
	imageType: ProductImageType
	bestSellerBrand: string
	catalog: {
		catalogId: string
		title: string
	}
	customProducts: {
		title: string
		products: CrossSellingServiceResponse[]
	}
	sectionType: SectionType.CrossSellingCarousel
	uid: string
}

export type CarouselBanner = {
	titleKey: string
	imageType: MandatoryProductImageType
	type: CarouselBannerType
	sectionType: SectionType.CarouselBanner
	cta: SectionSimpleCta | undefined
	products: CrossSellingServiceResponse[] | undefined
	promotionName: string
	uid: string
}

export interface SeoBannerLink extends SectionSimpleCta {
	show: {
		desktop: boolean
		mobile: boolean
	}
}

export type SeoBanner = {
	uid: string
	sectionType: SectionType.SeoBanner
	seoLinks: SeoBannerLink[]
}

export type MangoLikesYouBanner = {
	uid: string
	promotionName: string
	sectionType: SectionType.MangoLikesYouBanner
}

export type ParamsType = {
	country: string
	type: BrandId | HomeTypes | null
	csBranch?: string
}

export type BrandByUrlResponseType = [
	{
		legacyId: string
		id: HomeTypes
		url: string
		legacyUrl: string
		brand: string
		pageType: string
		isLegacy: string
	},
]

export enum HomeTypes {
	Multibrand = 'multibrand',
	Woman = 'she',
	Man = 'he',
	Teen = 'teen',
	Kids = 'kids',
	Home = 'home',
	Developer = 'dev',
}

export enum CrossSellingModelType {
	BestSellers = 'BestSellers',
	CatalogId = 'CatalogId',
	Custom = 'Custom',
}

export enum bestSellersId {
	outlet = 'best_sellers_she',
	outletH = 'best_sellers_he',
	outletA = 'best_sellers_nina',
	outletO = 'best_sellers_nino',
	outletP = 'best_sellers_teenP',
	outletQ = 'best_sellers_teenQ',
}

export enum ProductImageMapperType {
	B = 'B',
	D1 = 'D1',
	D2 = 'D2',
	D3 = 'D3',
	D4 = 'D4',
	D5 = 'D5',
	D6 = 'D6',
	D8 = 'D8',
	D9 = 'D9',
	F = 'F',
	O1 = 'O1',
	O2 = 'O2',
	R = 'R',
}

export interface CrossSellingServiceResponse {
	productId: string
	colorId: string
	lookId: string
}

'use client'

import { useFetch } from 'hooks/useFetch/useFetch'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import { Domains } from 'monitoring/constants/domains'
import { useLog } from 'monitoring/sendLog/client/useLog'
import { env } from 'utils/envManager'
import { url } from 'utils/url'

export function useSetBannerAsViewed() {
	const { channel } = useMasterData()
	const { fetcher } = useFetch()
	const { sendLog } = useLog()

	const setBannerAsViewed = async ({ bannerId }: { bannerId: string }) => {
		try {
			await fetcher({
				url: url(
					`${env.NEXT_PUBLIC_SITE_BASE_URL}/ws-my-purchases/${channel}/purchases/banner/viewed`
				),
				method: 'POST',
				body: { bannerId },
			})
		} catch {
			sendLog({
				domain: Domains.Home,
				message: 'Failed to mark banner as viewed',
			})
		}
	}

	return { setBannerAsViewed }
}

'use client'

import { useRef } from 'react'
import { cx } from 'utils/cx'

import {
	type Cta,
	type HeroBannerPromo,
	HeroBannerPromoType,
} from '../../../../Home.type'
import { InfoTooltip } from '../../components/InfoTooltip/InfoTooltip'
import { ButtonCtas } from '../../ctas/ButtonCtas/ButtonCtas'
import { useActiveLink } from '../../ctas/hooks/onActiveLink'
import { HeroBannerPromoCountdown } from './HeroBannerPromoCountdown'
import { HeroBannerPromoFamily } from './HeroBannerPromoFamily'
import { HeroBannerPromoGeneral } from './HeroBannerPromoGeneral'
import { HeroBannerPromoImage } from './HeroBannerPromoImage'
import { HeroBannerPromoProgressive } from './HeroBannerPromoProgressive'

import text from 'fukku/styles/texts.module.scss'
import stylesBGColor from '../../PromotionsBackgroundColors.module.scss'
import styles from './HeroBannerPromo.module.scss'

type HeroBannerPromoContentProps = {
	readonly section: HeroBannerPromo
	readonly ctas: Cta[]
	readonly infoLabel: string
	readonly onlineExclusiveLabel: string
	readonly isMobile: boolean
	readonly isPrioritySlot: boolean
}

type HeroBannerPromoTypeProps = {
	readonly section: HeroBannerPromo
}

type HeroBannerPromoSubcomponent =
	| typeof HeroBannerPromoGeneral
	| typeof HeroBannerPromoProgressive
	| typeof HeroBannerPromoFamily

function HeroBannerPromoTypeComponent({ section }: HeroBannerPromoTypeProps) {
	const promoMap: Record<HeroBannerPromoType, HeroBannerPromoSubcomponent> = {
		[HeroBannerPromoType.general]: HeroBannerPromoGeneral,
		[HeroBannerPromoType.progressive]: HeroBannerPromoProgressive,
		[HeroBannerPromoType.family]: HeroBannerPromoFamily,
	}

	const PromoComponent = promoMap[section.promoType]

	return <PromoComponent section={section} />
}

export function HeroBannerPromoContent({
	section,
	ctas,
	infoLabel,
	onlineExclusiveLabel,
	isMobile,
	isPrioritySlot,
}: HeroBannerPromoContentProps): React.ReactNode {
	const {
		image,
		countdown,
		promoBackgroundColor,
		promoLegalInfo,
		promoSubtitles: { condition, onlineExclusive, dueDate, promoCode },
	} = section

	const ref = useRef<HTMLDivElement>(null)
	const activeLink = useActiveLink(ref)

	return (
		<div className={styles.bannerWrapper} ref={ref}>
			<div className={styles.layout}>
				<div className={styles.imageWrapper}>
					<HeroBannerPromoImage image={image} priority={isPrioritySlot} />
				</div>
				<div
					className={cx(styles.solidColor, stylesBGColor[promoBackgroundColor])}
				/>
			</div>
			<div className={styles.layout}>
				<div className={styles.promoGeneral}>
					<div className={styles.promo}>
						<div className={cx(styles.counter, text.titleXL)}>
							<HeroBannerPromoCountdown date={countdown} />
						</div>
						<HeroBannerPromoTypeComponent section={section} />
						<h3 className={cx(styles.subtitles, text.titleM)}>
							<div>
								{[condition, onlineExclusive && onlineExclusiveLabel]
									.filter(Boolean)
									.join(' | ')}
							</div>
							<div>{[dueDate, promoCode].filter(Boolean).join(' | ')}</div>
						</h3>
					</div>
					<ButtonCtas
						className={styles.ctas}
						ctas={ctas}
						activeLink={activeLink}
					/>
					<InfoTooltip
						className={styles.infoTooltip}
						title={infoLabel}
						showInfo={Boolean(promoLegalInfo)}
						infoText={promoLegalInfo}
						position={isMobile ? 'bottom' : 'left'}
						inverse
					/>
				</div>
			</div>
		</div>
	)
}

'use client'

import { useViewPromotion } from 'analytics/hooks/useViewPromotion/useViewPromotion'
import type { Promotion } from 'analytics/types/EEC'
import CommsBannerCarouselComponent from 'fukku/CommsBanners/CommsBannerCarousel/CommsBannerCarousel'
import { useHeaderHeight } from 'hooks/useHeaderHeight/useHeaderHeight'
import { useCallback, useEffect, useRef, useState } from 'react'

import type {
	CommBanner,
	CommCtaShop,
	SectionType,
} from '../../../../Home.type'
import { commsToCommsWithLinksAndPromotions } from './commsToCommsWithLinksAndPromotions'

interface CommsCarouselClientProps {
	readonly sectionType: SectionType.CommsCarousel
	readonly slot: string
	readonly sectionUid: string
	readonly previousButtonLabel: string
	readonly nextButtonLabel: string
	readonly infoIconLabel: string
	readonly closeButtonLabel: string
	readonly allCommsBrandCtas: CommCtaShop[]
	readonly allCommsSectionCtas: CommCtaShop[]
	readonly comms: CommBanner[]
}

export interface CurrentVisibleComm {
	id: string
	commRef: React.RefObject<HTMLDivElement> | null
}

export function CommsCarouselClient({
	sectionType,
	slot,
	sectionUid,
	previousButtonLabel,
	nextButtonLabel,
	infoIconLabel,
	closeButtonLabel,
	allCommsBrandCtas,
	allCommsSectionCtas,
	comms,
}: CommsCarouselClientProps) {
	const carouselRef = useRef<HTMLDivElement>(null)
	const { headerHeight } = useHeaderHeight()
	const [currentVisibleCommId, setCurrentVisibleCommId] = useState<string>(
		comms[0].uid
	)

	const handleOnShowComm = useCallback(
		({ id }: CurrentVisibleComm) => {
			setCurrentVisibleCommId(id)
		},
		[currentVisibleCommId]
	)

	const { commsPromotions, allCommsWithLinks } =
		commsToCommsWithLinksAndPromotions({
			comms,
			allCommsBrandCtas,
			allCommsSectionCtas,
			infoIconLabel,
			closeButtonLabel,
			sectionType,
			slot,
			handleOnShowComm,
		})

	const filteredPromotions = commsPromotions.find(
		({ id }) => id === currentVisibleCommId
	)?.promotions as Promotion[]

	useViewPromotion({
		ref: carouselRef,
		promotions: filteredPromotions,
		elementKey: currentVisibleCommId,
	})

	const handleResize = () => {
		const carouselHeight =
			carouselRef.current?.getBoundingClientRect().height ?? 0

		if (carouselHeight === 0) {
			return
		}

		const newHeight = Math.round(headerHeight + carouselHeight)
		const bodyElement = carouselRef.current?.ownerDocument.body
		bodyElement?.style.setProperty('--reduce-height', `${newHeight}px`)
	}

	useEffect(() => {
		if (carouselRef.current) {
			handleResize()

			const resizeObserver = new ResizeObserver(handleResize)
			resizeObserver.observe(carouselRef.current)

			return () => {
				resizeObserver.disconnect()
			}
		}
		return undefined
	}, [headerHeight])

	if (allCommsWithLinks.length === 0) {
		return null
	}

	return (
		<div ref={carouselRef}>
			<CommsBannerCarouselComponent
				id={sectionUid}
				previousButtonLabel={previousButtonLabel}
				nextButtonLabel={nextButtonLabel}
				comms={allCommsWithLinks}
			/>
		</div>
	)
}

'use client'

import type { PointOfInterestImage } from 'landings/Home/Home.type'
import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import Image from 'next/image'
import { cx } from 'utils/cx'
import styles from './HeroBannerPromoImage.module.scss'

type HeroBannerPromoImageProps = {
	readonly image: PointOfInterestImage
	readonly className?: string
	readonly priority?: boolean
}

const ASPECT_RATIO_PORTRAIT_WIDTH = 5
const ASPECT_RATIO_PORTRAIT_HEIGHT = 7
const ASPECT_RATIO_PORTRAIT =
	ASPECT_RATIO_PORTRAIT_WIDTH / ASPECT_RATIO_PORTRAIT_HEIGHT

const PERCENTAGE = 0.01

export function HeroBannerPromoImage({
	className,
	image,
	priority = false,
}: HeroBannerPromoImageProps) {
	const pointOfInterest = {
		x: image.width * image.pointOfInterest.percentageX * PERCENTAGE,
		y: image.height * image.pointOfInterest.percentageY * PERCENTAGE,
	}
	const familyBannerImageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => width / ASPECT_RATIO_PORTRAIT
	)

	return (
		<Image
			className={cx(styles.image, className)}
			alt={image.description ?? ''}
			priority={priority}
			src={image.url}
			loader={familyBannerImageLoader}
			sizes='(min-width: 1024px) calc(75vh * 5 / 7), 100vw'
			draggable={false}
			fill
		/>
	)
}

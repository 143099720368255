import { getHorizontalScrollEvents } from 'fukku/HorizontalScroll/utils/getHorizontalScrollEvents'
import { useDocumentDirection } from 'hooks/useDocumentDirection/useDocumentDirection'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import {
	type PointerEvent,
	type WheelEvent,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'

enum ScrollDirectionType {
	LEFT = 'LEFT',
	RIGHT = 'RIGHT',
}

const NUMBER_CHILDREN_SCROLL = 2

export const useHorizontalScroll = (initialPosition?: number) => {
	const { isMobile } = useMasterData()
	const { isRtl } = useDocumentDirection()

	const horizontalScrollRef = useRef<HTMLDivElement | HTMLUListElement | null>(
		null
	)
	const horizontalScrollGap = useRef<number>(0)
	const firstElementChildWidth = useRef<number>(0)

	const [isAtStart, setIsAtStart] = useState<boolean>(true)
	const [isAtEnd, setIsAtEnd] = useState<boolean>(true)
	const [scrollLeft, setScrollLeft] = useState<number>(0)
	const [touchStartX, setTouchStartX] = useState<number | null>(null)
	const [scrollStartX, setScrollStartX] = useState<number | null>(null)
	const [isDragging, setIsDragging] = useState<boolean>(false)

	const shouldShowControls = !isAtStart || !isAtEnd

	const updateScrollButtonsState = useCallback(() => {
		const refScrollWidth = horizontalScrollRef.current?.scrollWidth ?? 0
		const refScrollClientWidth = horizontalScrollRef.current?.clientWidth ?? 0
		const maxScrollLeft = refScrollWidth - refScrollClientWidth

		const directionScrollLeft = scrollLeft * (isRtl ? -1 : 1)

		setIsAtStart(directionScrollLeft <= 0)
		setIsAtEnd(directionScrollLeft >= maxScrollLeft - 1)
	}, [scrollLeft])

	const handleDragStart = useCallback((e: PointerEvent<HTMLElement>) => {
		const refScrollLeft = horizontalScrollRef.current?.scrollLeft ?? 0

		setTouchStartX(e.screenX)
		setScrollStartX(refScrollLeft)
		setIsDragging(true)
	}, [])

	const handleDragMove = useCallback(
		(e: PointerEvent<HTMLElement>) => {
			e.preventDefault()
			e.stopPropagation()

			const isDragInitialized =
				touchStartX !== null && scrollStartX !== null && isDragging

			if (horizontalScrollRef.current && isDragInitialized) {
				horizontalScrollRef.current.scrollLeft =
					scrollStartX - (touchStartX - e.screenX)
			}
		},
		[touchStartX, isDragging, scrollStartX]
	)

	const handleDragEnd = useCallback(() => {
		if (isDragging) {
			setTouchStartX(null)
			setScrollStartX(null)
			setIsDragging(false)
		}
	}, [isDragging])

	const handleScrollOnClick = useCallback(
		(scrollDirection: ScrollDirectionType) => {
			const currentScrollLeft = horizontalScrollRef.current?.scrollLeft ?? 0
			const currentChildrenWidth =
				NUMBER_CHILDREN_SCROLL *
				(firstElementChildWidth.current + horizontalScrollGap.current)

			const directionCurrentChildrenWidth =
				currentChildrenWidth * (isRtl ? -1 : 1)

			const targetScrollLeft =
				scrollDirection === ScrollDirectionType.LEFT
					? currentScrollLeft - directionCurrentChildrenWidth
					: currentScrollLeft + directionCurrentChildrenWidth

			setScrollLeft(targetScrollLeft)

			horizontalScrollRef.current?.scrollTo({
				left: targetScrollLeft,
				behavior: 'smooth',
			})
		},
		[]
	)

	const handleLeftScroll = useCallback(() => {
		handleScrollOnClick(ScrollDirectionType.LEFT)
	}, [])

	const handleRightScroll = useCallback(() => {
		handleScrollOnClick(ScrollDirectionType.RIGHT)
	}, [])

	const handleOnWheel = (event: WheelEvent<HTMLElement>) => {
		const isOnXAxis = event.deltaX !== 0

		if (isOnXAxis) {
			const currentScrollLeft = horizontalScrollRef.current?.scrollLeft ?? 0
			const newScrollLeft = currentScrollLeft + event.deltaX
			const refScrollWidth = horizontalScrollRef.current?.scrollWidth ?? 0
			const refScrollClientWidth = horizontalScrollRef.current?.clientWidth ?? 0

			if (refScrollWidth !== refScrollClientWidth) {
				setScrollLeft(newScrollLeft)
			}
		}
	}

	const mobileEvents = {
		onPointerDown: handleDragStart,
		onPointerMove: handleDragMove,
		onPointerUp: handleDragEnd,
		onPointerCancel: handleDragEnd,
	}

	const desktopEvents = {
		onWheel: handleOnWheel,
	}

	const horizontalScrollEvents = getHorizontalScrollEvents({
		isMobile,
		mobileEvents,
		desktopEvents,
	})

	const updateScrollWidth = useCallback(() => {
		if (horizontalScrollRef.current) {
			horizontalScrollGap.current = Number.parseInt(
				getComputedStyle(horizontalScrollRef.current).gap,
				10
			)
		}

		firstElementChildWidth.current = horizontalScrollRef.current
			?.firstElementChild
			? horizontalScrollRef.current.firstElementChild.clientWidth
			: 0

		updateScrollButtonsState()
	}, [])

	useEffect(() => {
		const observer = new ResizeObserver(updateScrollWidth)

		horizontalScrollRef.current?.firstElementChild &&
			observer.observe(horizontalScrollRef.current)

		return () => {
			observer.disconnect()
		}
	}, [])

	useEffect(() => {
		updateScrollButtonsState()
	}, [scrollLeft])

	useEffect(() => {
		const horizontalScrollRefElement = horizontalScrollRef.current
		if (
			!horizontalScrollRefElement ||
			!initialPosition ||
			initialPosition <= 0
		) {
			return
		}
		const targetChild = horizontalScrollRefElement.childNodes[initialPosition]
		if (!targetChild || !(targetChild instanceof HTMLElement)) {
			return
		}
		const scrollRect = horizontalScrollRefElement.getBoundingClientRect()
		const childRect = targetChild.getBoundingClientRect()
		const isElementVisible =
			childRect.left >= scrollRect.left && childRect.right <= scrollRect.right

		if (!isElementVisible) {
			targetChild.scrollIntoView({ behavior: 'instant', inline: 'center' })
			setScrollLeft(targetChild.offsetLeft)
		}
	}, [initialPosition])

	return {
		isAtStart,
		isAtEnd,
		scrollStartX,
		touchStartX,
		isDragging,
		handleLeftScroll,
		handleRightScroll,
		horizontalScrollEvents,
		horizontalScrollRef,
		shouldShowControls,
	}
}

'use client'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { type RefObject, useEffect } from 'react'

import { useAnalyticsPromotions } from '../../contexts/AnalyticsPromotionsProvider'
import type { Promotion } from '../../types/EEC'

type ViewPromotionProps = {
	ref: RefObject<HTMLDivElement>
	promotions: Promotion[]
	elementKey?: string
}

export const useViewPromotion = ({
	ref,
	promotions,
	elementKey,
}: ViewPromotionProps) => {
	const { addPromotions } = useAnalyticsPromotions()
	const entry = useIntersectionObserver(ref, {
		threshold: 0.7,
		freezeOnceVisible: true,
	})

	useEffect(() => {
		if (entry?.isIntersecting) {
			addPromotions(promotions)
		}
	}, [entry?.isIntersecting, elementKey])
}

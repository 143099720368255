import { AnalyticsEvents } from 'analytics/constants/events'
import { sendGenericEvent } from 'analytics/events/generics/sendGenericEvent'
import type { Carousels } from 'landings/types/Carousels.type'
import { useState } from 'react'

export const useScrollAnalytics = () => {
	const [isFirstScroll, setIsFirstScroll] = useState(true)

	const sendScrollAnalytic = (location: Carousels) => {
		if (isFirstScroll) {
			sendGenericEvent(AnalyticsEvents.HORIZONTAL_SCROLL, {
				location,
			})
			setIsFirstScroll(false)
		}
	}

	return {
		sendScrollAnalytic,
	}
}

'use client'

import type {
	CarouselAction,
	CarouselTitle,
	CarouselVariants,
} from 'fukku/Carousel/types'
import type { ProductImageType } from 'product/types'
import type { ProductLocations } from 'product/types/ProductLocations'
import type { BrandId } from 'types/brands'

import { useCollaborativeRecommendations } from '../../../hooks/useCollaborativeRecommendations'
import type { CrossSellingServiceResponse } from '../../../types'
import { ClientCrossSelling } from '../../shared/CrossSelling/client/ClientCrossSelling'

export interface CollaborativeRecommendationsCrossSellingProps {
	readonly dataTestId: string
	readonly location: ProductLocations
	readonly serverCollaborativeRecommendationsProducts: CrossSellingServiceResponse[]
	readonly titleProps: CarouselTitle
	readonly className?: string
	readonly hasFavoriteButton?: boolean
	readonly variant?: CarouselVariants
	readonly action?: CarouselAction
	readonly imageType?: ProductImageType[]
	readonly brandId?: BrandId
}

export function CollaborativeRecommendationsCrossSelling({
	dataTestId,
	location,
	serverCollaborativeRecommendationsProducts,
	titleProps,
	className,
	hasFavoriteButton,
	variant,
	action,
	imageType,
	brandId,
}: CollaborativeRecommendationsCrossSellingProps): JSX.Element | boolean {
	const { doFetch, data: clientCollaborativeRecommendationsProducts } =
		useCollaborativeRecommendations(brandId)

	const collaborativeRecommendationsProducts = doFetch
		? clientCollaborativeRecommendationsProducts
		: serverCollaborativeRecommendationsProducts

	const imageSortCriteria: ProductImageType[] = imageType ?? ['B']

	return (
		!!collaborativeRecommendationsProducts?.length && (
			<ClientCrossSelling
				crossSellingItems={collaborativeRecommendationsProducts}
				dataTestId={dataTestId}
				imageSortCriteria={imageSortCriteria}
				title={titleProps}
				location={location}
				productListId={location}
				className={className}
				hasFavoriteButton={hasFavoriteButton}
				variant={variant}
				action={action}
			/>
		)
	)
}

'use client'

import { Breakpoints } from 'fukku/styles/breakpoints'
import type { PointOfInterestImage } from 'landings/Home/Home.type'
import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import Image from 'next/image'

type LineBannerImageProps = {
	readonly image: PointOfInterestImage
	readonly priority?: boolean
}

const ASPECT_RATIO_WIDTH = 7
const ASPECT_RATIO_HEIGHT = 5
const ASPECT_RATIO = ASPECT_RATIO_WIDTH / ASPECT_RATIO_HEIGHT

export function LineBannerImage({
	image,
	priority = false,
}: LineBannerImageProps) {
	const percentage = 0.01

	const pointOfInterest = {
		x: image.width * image.pointOfInterest?.percentageX * percentage,
		y: image.height * image.pointOfInterest?.percentageY * percentage,
	}

	const lineBannerImageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => width / ASPECT_RATIO
	)

	return (
		<Image
			fill
			alt={image.description ?? ''}
			priority={priority}
			src={image.url}
			style={{
				objectFit: 'cover',
				objectPosition: `${image.pointOfInterest.percentageX}% ${image.pointOfInterest.percentageY}%`,
			}}
			loader={lineBannerImageLoader}
			sizes={`(min-width: ${Breakpoints.medium}px) 47vw, 90vw`}
			draggable={false}
		/>
	)
}

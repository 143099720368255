'use client'

import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'

import type { ErrorManagerClass } from '../../components/ErrorManager/ErrorManager'
import { Domains } from '../../constants/domains'
import { Types } from '../../constants/types'
import type { SendLogParams } from '../../types/ErrorsLogs'

export function useLog() {
	const {
		brand,
		country: { countryISO, languageISO },
		device,
		userAgent,
		pageType,
		referer,
		url,
	} = useMasterData()
	const error: ErrorManagerClass & { name: string; type: string } = {
		country: countryISO,
		domain: Domains.Default,
		language: languageISO,
		message: 'Default message',
		name: '[Genesis]',
		type: 'error',
		brand,
		device,
		userAgent,
		pageType,
		referer,
		url,
	}

	const sendLog = ({
		custom,
		domain,
		message,
		type = Types.Error,
	}: SendLogParams): void => {
		error.custom = custom
		error.domain = domain
		error.message = message
		error.name = '[Genesis]'
		error.type = type

		console.error(error)
	}

	return { sendLog }
}

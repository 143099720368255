'use client'

import { Breakpoints } from 'fukku/styles/breakpoints'
import type { PointOfInterestImage } from 'landings/Home/Home.type'
import { pointOfInterestImageLoaderFactory } from 'landings/utils/pointOfInterestImageLoaderFactory'
import Image from 'next/image'
import { cx } from 'utils/cx'

import styles from './HeroBannerSecondaryLinesImage.module.scss'

type HeroBannerImageProps = {
	readonly className?: string
	readonly image: PointOfInterestImage
	readonly isAlwaysPortrait?: boolean
	readonly priority?: boolean
}

const SMALL_MEDIUM_HEIGHT = 375

const LARGE_HEIGHT = 440

export function HeroBannerSecondaryLinesImage({
	className,
	image,
	priority = false,
}: HeroBannerImageProps) {
	const percentage = 0.01

	const pointOfInterest = {
		x: image.width * image.pointOfInterest.percentageX * percentage,
		y: image.height * image.pointOfInterest.percentageY * percentage,
	}
	const heroBannerImageLoader = pointOfInterestImageLoaderFactory(
		pointOfInterest,
		(width) => {
			if (width <= Breakpoints.large) {
				return SMALL_MEDIUM_HEIGHT
			}
			return LARGE_HEIGHT
		}
	)

	return (
		<Image
			className={cx(className, styles.heroBannerSecondaryLinesImage)}
			fill
			alt=''
			aria-hidden
			priority={priority}
			src={image.url}
			style={{
				objectPosition: `${image.pointOfInterest.percentageX}% ${image.pointOfInterest.percentageY}%`,
			}}
			loader={heroBannerImageLoader}
		/>
	)
}

'use client'

import type { BackInStockProps } from 'back-in-stock/src/types'
import {
	type ReactNode,
	createContext,
	useCallback,
	useMemo,
	useState,
} from 'react'

interface BackInStockModalContextProps {
	isOpen: boolean
	backInStockModalProps: BackInStockProps | null
	openProductSubscription: (
		openProductSubscriptionProps: BackInStockProps
	) => void
	closeProductSubscription: () => void
	setBackInStockModalProps: (backInStockModalProps: BackInStockProps) => void
}

interface BackInStockModalProviderProps {
	readonly children: ReactNode
}

export const BackInStockModalContext =
	createContext<BackInStockModalContextProps | null>(null)

export const BackInStockModalProvider = ({
	children,
}: BackInStockModalProviderProps) => {
	const [isOpen, setIsOpen] = useState<boolean>(false)
	const [backInStockModalProps, setBackInStockModalProps] =
		useState<BackInStockProps | null>(null)

	const openProductSubscription = useCallback(
		(openProductSubscriptionProps: BackInStockProps) => {
			setBackInStockModalProps(openProductSubscriptionProps)
			setIsOpen(true)
		},
		[]
	)

	const closeProductSubscription = useCallback(() => {
		setIsOpen(false)
	}, [])

	const values = useMemo(
		() => ({
			isOpen,
			backInStockModalProps,
			openProductSubscription,
			closeProductSubscription,
			setBackInStockModalProps,
		}),
		[
			isOpen,
			backInStockModalProps,
			openProductSubscription,
			closeProductSubscription,
			setBackInStockModalProps,
		]
	)

	return (
		<BackInStockModalContext.Provider value={values}>
			{children}
		</BackInStockModalContext.Provider>
	)
}

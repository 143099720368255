'use client'

import { ServerLabel } from 'labels/server'
import type { Cta, PointOfInterestImage } from 'landings/Home/Home.type'
import { Marquee } from 'landings/components/Marquee/Marquee'
import { useRef } from 'react'

import { MarqueeProvider } from '../../../../../components/Marquee/MarqueeProvider'
import { ButtonCtas } from '../../ctas/ButtonCtas/ButtonCtas'
import { useActiveLink } from '../../ctas/hooks/onActiveLink'
import {
	DEVICE_TYPE,
	HeroBannerImage,
} from '../components/HeroBannerImage/HeroBannerImage'
import { AnimationButton } from './AnimationButton'
import type { AnimationButtonTitles } from './HeroBannerPrimaryLines'

import styles from './HeroBannerPrimaryLines.module.scss'

type HeroBannerLinesPrimaryComponentProps = {
	readonly image: PointOfInterestImage
	readonly ctas: Cta[]
	readonly isPrioritySlot: boolean
	readonly animationButtonTitles: AnimationButtonTitles
	readonly motionTitleLabel: string
	readonly isCicd?: boolean
}

const ASPECT_RATIO_SMALL_WIDTH = 5
const ASPECT_RATIO_SMALL_HEIGHT = 7
const ASPECT_RATIO_SMALL = ASPECT_RATIO_SMALL_WIDTH / ASPECT_RATIO_SMALL_HEIGHT

const ASPECT_RATIO_MEDIUM_HEIGHT = 600

const ASPECT_RATIO_LARGE_WIDTH = 7
const ASPECT_RATIO_LARGE_HEIGHT = 3
const ASPECT_RATIO_LARGE = ASPECT_RATIO_LARGE_WIDTH / ASPECT_RATIO_LARGE_HEIGHT

export function HeroBannerPrimaryLinesContent({
	image,
	ctas,
	isPrioritySlot,
	animationButtonTitles,
	motionTitleLabel,
	isCicd,
}: HeroBannerLinesPrimaryComponentProps): React.ReactNode {
	const ref = useRef<HTMLDivElement>(null)
	const activeLink = useActiveLink(ref)

	return (
		<div className={styles.bannerWrapper} ref={ref}>
			<HeroBannerImage
				image={image}
				deviceHeightCalc={{
					[DEVICE_TYPE.Mobile]: (width) => width / ASPECT_RATIO_SMALL,
					[DEVICE_TYPE.Tablet]: () => ASPECT_RATIO_MEDIUM_HEIGHT,
					[DEVICE_TYPE.Desktop]: (width) => width / ASPECT_RATIO_LARGE,
				}}
				priority={isPrioritySlot}
			/>
			<MarqueeProvider>
				{!isCicd && (
					<Marquee className={styles.marquee}>
						<span className={styles.title}>
							<ServerLabel boldProps={{ className: styles.boldTitle }}>
								{motionTitleLabel}
							</ServerLabel>
						</span>
					</Marquee>
				)}
				<ButtonCtas
					ctas={ctas}
					activeLink={activeLink}
					className={styles.ctas}
				/>
				<AnimationButton titles={animationButtonTitles} />
			</MarqueeProvider>
		</div>
	)
}

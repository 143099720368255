'use client'

import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import dynamic from 'next/dynamic'
import { useRef } from 'react'

import type { FamiliesCarousel } from '../../../Home.type'
import type { SectionDataType } from '../../../types/sectionData'

type ClientFamiliesCarouselComponentProps = {
	readonly section: FamiliesCarousel
	readonly sections: SectionDataType[]
	readonly sectionHref: string | undefined
	readonly slot: string
}

const DynamicClientFamiliesCarouselComponent = dynamic(() =>
	import('./DynamicClientFamiliesCarouselComponent').then(
		(mod) => mod.DynamicClientFamiliesCarouselComponent
	)
)

export const ClientFamiliesCarouselComponent = ({
	section,
	sections,
	sectionHref,
	slot,
}: ClientFamiliesCarouselComponentProps) => {
	const familiesCarouselRef = useRef<HTMLDivElement | null>(null)

	const familiesCarouselEntry = useIntersectionObserver(familiesCarouselRef, {
		threshold: [0, 1],
		rootMargin: '0px 0px 150px 0px',
		freezeOnceVisible: true,
	})

	return (
		<div ref={familiesCarouselRef}>
			{familiesCarouselEntry?.isIntersecting ? (
				<DynamicClientFamiliesCarouselComponent
					section={section}
					sections={sections}
					sectionHref={sectionHref}
					slot={slot}
				/>
			) : null}
		</div>
	)
}

'use client'

import { IconNextM } from 'icons/components/IconNextM'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { PURCHASE_DETAIL_PATHNAME } from 'my-purchases/constants/paths'
import { OrderStatusWithIcon } from 'order-status-banner/types/order/order'
import { useMemo } from 'react'
import { cx } from 'utils/cx'
import { ClientLink } from 'utils/link/client'

import { sendAnalytics } from '../../analyticsEvents/analyticsEvents'
import type { PurchaseDetail } from '../../types/purchase/purchase'
import { AnalyticsActionType } from '../../utils/constants'

import text from 'fukku/styles/texts.module.scss'
import styles from './OrderStatusLink.module.scss'

interface OrderStatusLinkProps {
	readonly onCloseBanner: () => void
	readonly purchaseDetails: PurchaseDetail
}

export const OrderStatusLink = ({
	onCloseBanner,
	purchaseDetails,
}: OrderStatusLinkProps) => {
	const { t } = useLabels()

	const {
		purchaseId,
		orders: [{ status }],
	} = purchaseDetails

	function handleClickDetails() {
		onCloseBanner()

		sendAnalytics({
			purchaseDetails,
			action: AnalyticsActionType.CLICK,
		})
	}

	const myPurchasesLink = useMemo(
		() => `${PURCHASE_DETAIL_PATHNAME}?type=online&purchaseId=${purchaseId}`,
		[purchaseId]
	)

	return (
		<ClientLink
			className={cx(
				styles.link,
				Object.values(OrderStatusWithIcon).includes(status) && styles.withIcon,
				text.bodyM
			)}
			href={myPurchasesLink}
			onClick={handleClickDetails}
			linkProps={{
				'aria-label': t(
					'accessibility.commsBanner.myPurchases.viewOrder.button'
				),
				'data-testid': 'orderStatusBanner.goToMyPurchases',
			}}
		>
			{t('myPurchases.banner.viewDetails.button')}
			<IconNextM width={18} height={18} className={styles.icon} />
		</ClientLink>
	)
}

'use client'

import type { DynamicCrossSellingProps } from 'cross-selling/types'
import { CarouselVariants } from 'fukku/Carousel/types'
import { useIntersectionObserver } from 'hooks/useIntersectionObserver/useIntersectionObserver'
import { useMasterData } from 'master-data/hooks/useMasterData/useMasterData'
import dynamic from 'next/dynamic'
import { useRef } from 'react'

const DynamicServerCrossSelling = dynamic(() =>
	import('./DynamicServerCrossSelling').then(
		(mod) => mod.DynamicServerCrossSelling
	)
)

export const ContentServerCrossSelling = ({
	crossSellingItems,
	imageSortCriteria,
	location,
	productListId,
	'data-testid': dataTestId,
	title,
	variant = CarouselVariants.VARIANT2,
	action,
	showDiscount,
	className,
}: DynamicCrossSellingProps) => {
	const {
		country: { hasOnlineSale },
	} = useMasterData()

	const serverCrossSellingRef = useRef(null)

	const productCardEntry = useIntersectionObserver(serverCrossSellingRef, {
		threshold: [0, 1],
		rootMargin: '0px 0px 150px 0px',
		freezeOnceVisible: true,
	})

	return (
		<div ref={serverCrossSellingRef}>
			{productCardEntry?.isIntersecting ? (
				<DynamicServerCrossSelling
					crossSellingItems={crossSellingItems}
					imageSortCriteria={imageSortCriteria}
					location={location}
					productListId={productListId}
					data-testid={dataTestId}
					title={title}
					variant={variant}
					action={action}
					showDiscount={showDiscount}
					className={className}
					hasOnlineSale={hasOnlineSale}
				/>
			) : null}
		</div>
	)
}

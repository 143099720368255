'use client'

import { ClientLabel } from 'labels/client'
import { useLabels } from 'labels/hooks/useLabels/useLabels'
import { OrderStatusWithIcon } from 'order-status-banner/types/order/order'
import type { PurchaseDetail } from 'order-status-banner/types/purchase/purchase'
import { getAlert } from 'order-status-banner/utils/getAlert/getAlert'
import { getIconByStatus } from 'order-status-banner/utils/getIconByStatus/getIconByStatus'
import { useSession } from 'session/src/hooks/useSession'
import { cx } from 'utils/cx'

import { OrderStatusText } from '../OrderStatusText/OrderStatusText'
import { OrderStatusWarning } from '../OrderStatusWarning/OrderStatusWarning'

import text from 'fukku/styles/texts.module.scss'
import styles from './OrderStatusStep.module.scss'

interface OrderStatusStepProps {
	readonly purchaseDetails: PurchaseDetail
}

export const OrderStatusStep = ({ purchaseDetails }: OrderStatusStepProps) => {
	const { t } = useLabels()

	const { name } = useSession()
	const userName = name ?? ''

	const {
		orders: [{ id, status, alert }],
		isMultiOrder,
	} = purchaseDetails
	const hasAlerts = !!getAlert(alert)
	const StatusIcon = getIconByStatus({ status, isMultiOrder })

	return (
		<div data-testid='orderStatusBanner.step'>
			<div className={styles.status}>
				{StatusIcon && (
					<div className={styles.iconContainer}>
						<StatusIcon
							className={cx(
								status === OrderStatusWithIcon.DELIVERED && styles.iconGreen
							)}
						/>
					</div>
				)}
				<div className={styles.info}>
					{isMultiOrder ? (
						<span className={styles.stateText}>
							<ClientLabel placeholderValues={{ 1: userName }}>
								{t('myPurchases.banner.generalStatus.body') ?? ''}
							</ClientLabel>
						</span>
					) : (
						<>
							<span className={text.bodyM}>
								<ClientLabel placeholderValues={{ 1: id }}>
									{t('mypurchases.home.header.title.order') ?? ''}
								</ClientLabel>
							</span>
							<span className={styles.stateText}>
								{hasAlerts && alert ? (
									<OrderStatusWarning alert={alert} />
								) : (
									<OrderStatusText status={status} id={id} />
								)}
							</span>
						</>
					)}
				</div>
			</div>
		</div>
	)
}

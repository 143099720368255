'use client'
import dynamic from 'next/dynamic'
import type { PromotionalBannerTextProps } from './PromotionalBannerTextTypes'

const DynamicPromotionalBannerText = dynamic(() =>
	import('./DynamicPromotionalBannerText').then(
		(mod) => mod.DynamicPromotionalBannerText
	)
)

export const PromotionalBannerText = ({
	subtitleTopKey,
	promotionTitle,
	isLargeDiscount,
	discount,
	subtitlesGroup,
	subtitleBottomKey,
	isLow,
	contentColorType,
	customContentColor,
}: PromotionalBannerTextProps) => (
	<DynamicPromotionalBannerText
		subtitleTopKey={subtitleTopKey}
		promotionTitle={promotionTitle}
		isLargeDiscount={isLargeDiscount}
		discount={discount}
		subtitlesGroup={subtitlesGroup}
		subtitleBottomKey={subtitleBottomKey}
		isLow={isLow}
		contentColorType={contentColorType}
		customContentColor={customContentColor}
	/>
)

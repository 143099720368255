'use client'

import { sendSelectPromotionEvent } from '../../events/eec/selectPromotion/selectPromotion'
import type { Promotion } from '../../types/EEC'

type SelectPromotionProps = {
	readonly children: React.ReactNode
	readonly promotion: Promotion
}

export const SelectPromotion = ({
	promotion,
	children,
}: SelectPromotionProps) => {
	function handleClick() {
		sendSelectPromotionEvent(promotion)
	}

	return (
		<div onKeyDown={handleClick} onClick={handleClick}>
			{children}
		</div>
	)
}

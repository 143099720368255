'use client'
import { DynamicPromotionalBannerCtas } from './DynamicPromotionalBannerCtas'
import type { PromotionalBannerCtasProps } from './PromotionalBannerCtasTypes'

export const PromotionalBannerCtas = ({
	ctas,
	isLow,
	isSingleCta,
}: PromotionalBannerCtasProps) => (
	<DynamicPromotionalBannerCtas
		ctas={ctas}
		isLow={isLow}
		isSingleCta={isSingleCta}
	/>
)
